function addQueryParams(url, params) {
  const urlObj = new URL(url);

  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined && params[key] !== null) {
      urlObj.searchParams.append(key, params[key]);
    }
  });

  return urlObj.toString();
}

export default addQueryParams;
// // Example usage:
// const url = "https://example.com/api";
// const params = { search: "test", page: 2, filter: "active" };
// console.log(addQueryParams(url, params));
// // Output: "https://example.com/api?search=test&page=2&filter=active"
