const getParseEnviroment = (data) =>{
    let setup = [];
      for(let key in data){
           const str = key.split('_')
         if(str[0] === "setup"){
           setup.push({ env : data[key]})
         }
         }
       for(let obj of setup){
          let key = obj.env;
           console.log(key)
           obj.tenants = data[`${key}`];
           obj.setupUrl = data[`commonBaseUrl_${key}`];
     }
      return setup
    }
    
    export  { getParseEnviroment }