import { STRINGS_EN } from "../constant";
import { getParseEnviroment } from "./getParseEnvironment";

const getSelectedEnv = (data) => {
  const { selectedEnv, selectedSetupUrl, selectedTenant } = data;
  return selectedEnv && selectedSetupUrl && selectedTenant
    ? { selectedEnv, selectedSetupUrl, selectedTenant }
    : {};
};

const prepareEnvironments = (data) => {
  return getParseEnviroment(data);
};

const getTenant = (env) => {
  try {
    const parseData = JSON.parse(env?.tenants);
    return parseData || [];
  } catch (err) {
    console.error("Error while parsing tenant", err);
    return [];
  }
};

export const getLoader = (theme) => {
  console.log({ theme });
  return (
    <span style={theme === "dark" ? { color: "white" } : { color: "black" }}>
      {STRINGS_EN.LOADING}
    </span>
  );
};

const getContextDetails = (context) => {
  const aadObjectID = context?.id;
  const email = context?.userPrincipalName;
  const tenantID = context?.tenant?.id;
  const tenantTeamsSku = context?.tenant?.teamsSku;
  return { aadObjectID, email, tenantID, tenantTeamsSku };
};

export { getSelectedEnv, prepareEnvironments, getTenant, getContextDetails };
